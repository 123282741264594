import React, {useState} from 'react';
import {Button, Input, Wrapper} from "./Form.styled";

const Form: React.FC = () => {
	const [inputData, setInputData] = useState('');

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const url = `${process.env.REACT_APP_GOOGLE_FORM_URL}/formResponse`; // Змініть на ваш URL
		const formData = new FormData();
		formData.append(`${process.env.REACT_APP_GOOGLE_FORM_ENTRY}`, inputData);
		try {
			await fetch(url, {
				method: 'POST',
				mode: 'no-cors',
				body: formData,
			});

			alert('Дані надіслані.');
		} catch (error) {
			alert(`Помилка: ${error instanceof Error ? error.message : 'Невідома помилка'}`);
		}
	};

	return (
		<Wrapper onSubmit={handleSubmit}>
			<Input
				type="email"
				value={inputData}
				onChange={(e) => setInputData(e.target.value)}
				required
				placeholder='Email'
			/>
			<Button type="submit">Subscribe</Button>
		</Wrapper>
	);
};

export default Form;
