import styled from "styled-components";

export const Wrapper = styled.form`
	height: 27px;
	display: inline-block;
`

export const Input = styled.input`
    background-color: transparent;
    outline: none;
	border: 1px solid white;
	border-radius: 0;
    color: white;
    height: 100%;
    width: 194px;
    margin-top: 24px;
    font-size: 15px;
	padding-left: 11px;
	padding-right: 11px;

    &::placeholder {
        color: white;
    }

    @media (max-width: 340px) {
		width: calc(100% - 115px);
    }
`

export const Button = styled.button`
	background-color: white;
	color: black;
	height: 100%;
	width: 105px;
	margin-left: 3px;
    font-size: 15px;
	cursor: pointer;
`